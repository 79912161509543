<template>
  <div>
    <div class="d-none d-md-block">
      <div class="page-header page-header-medium">
        <div
          class="page-header-image"
          :style="{ 'background-image': 'url(' + header + ')' }"
        ></div>
        <div class="content-center">
          <div class="container-max-width header-text">
            <h1 class="text-white text-center">PAHUKAMA</h1>
            <div class="text-center">
              <h4 class="text-white">
                Shop directly from Zimbabwe's merchants.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-md-block mt-4">
      <div class="desktop-section">
        <div class="w-100 d-flex justify-content-center">
          <el-carousel
            trigger="click"
            :height="this.windowWidth < 500 ? '130px' : '450px'"
            class="container-max-width w-100"
          >
            <el-carousel-item
              class="el-carousel__item is-active is-animating"
              
              style="transform: translateX(0px) scale(1)"
            >
              <img :src="slide" alt="First slide" class="d-block" />
            </el-carousel-item>
            <el-carousel-item
              class="el-carousel__item"
              style="transform: translateX(730px) scale(1)"
            >
              <img :src="slide1" alt="Second slide" class="d-block" />
            </el-carousel-item>
            <el-carousel-item
              class="el-carousel__item is-animating"
              style="transform: translateX(-730px) scale(1)"
            >
              <img :src="slide2" alt="Third slide" class="d-block" />
            </el-carousel-item>
            <el-carousel-item
              class="el-carousel__item is-animating"
              style="transform: translateX(-730px) scale(1)"
            >
              <img :src="slide3" alt="Forth slide" class="d-block" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>


    <div class="desktop-section no-bottom mt-4">
      <div class="container-max-width">
        <div style="background-color: #ffe8df;" class="d-flex justify-content-between pt-4 p-2">
          <h3 class="text-left">PAHUKAMA SERVICES CENTER</h3>
        </div>
        <div class="row mx-1">
          <div class="p-3 col-md-3 col-6">
            <a href="/dialfrom263/" class="service">
              <div class="service__img">
                <img src="/img/landing/services/button1.png" alt="" />
              </div>
            </a>
          </div>
          <div class="p-3 col-md-3 col-6">
            <a href="/smart-topup" class="service">
              <div class="service__img">
                <img src="/img/landing/services/button3.png" alt="" />
              </div>
            </a>
          </div>
          <div class="p-3 col-md-3 col-6">
            <a href="/vouchers" class="service">
              <div class="service__img">
                <img src="/img/landing/services/button8.png" alt="" />
              </div>
            </a>
          </div>
          <div class="p-3 col-md-3 col-6">
            <a href="/runners/runner_requests" class="service">
              <div class="service__img">
                <img src="/img/landing/services/button2.png" alt="" />
              </div>
            </a>
          </div>
          <div class="p-3 col-md-3 col-6">
            <a href="/simple_pay_direct" class="service">
              <div class="service__img">
                <img src="/img/button4.png" alt="" />
              </div>
            </a>
          </div>
          <div class="p-3 col-md-3 col-6">
            <a href="/specialists-jobs-tasks" class="service">
              <div class="service__img">
                <img src="/img/button5.png" alt="" />
              </div>
            </a>
          </div>
          <div class="p-3 col-md-3 col-6">
            <a href="/prescriptions" class="service">
              <div class="service__img">
                <img src="/img/button11.png" alt="" />
              </div>
            </a>
          </div>
          <div class="p-3 col-md-3 col-6">
            <a href="/listings/category" class="service">
              <div class="service__img">
                <img src="/img/button9.png" alt="" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    

    <div class="desktop-section no-bottom">
      <div class="container-max-width">
        <div style="background-color: #ffe8df;" class="d-flex justify-content-between pt-4 p-2">
          <h3 class="text-left w-75">GREENWOOD PHARMACY HEALTH</h3>
          <router-link :to="{ path: 'shop/greenwood-pharmacy-online' }">
            <button class="btn btn-outline-dark btn-lg">View All</button>
          </router-link>
        </div>
        <div class="ps-shopping-product">
          <div class="row">
            <div
              v-for="product in greenwood"
              :key="product.id"
              class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6"
            >
              <Product-card :product="product"></Product-card>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="desktop-section no-bottom">
      <div class="container-max-width">
        <div style="background-color: #ffe8df;" class="d-flex justify-content-between pt-4 p-2">
          <h3 class="text-left w-75">BUTCHERBOX MEATS</h3>
          <router-link :to="{ path: 'shop/pahukama-butcherbox' }">
            <button class="btn btn-outline-dark btn-lg">View All</button>
          </router-link>
        </div>
        <div class="ps-shopping-product">
          <div class="row">
            <div
              v-for="product in butchery"
              :key="product.id"
              class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6"
            >
              <Product-card :product="product"></Product-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="desktop-section no-bottom">
      <div class="container-max-width">
        <div style="background-color: #ffe8df;" class="d-flex justify-content-between pt-4 p-2">
          <h3 class="text-left align-self-center w-75">COLCOM FOODS PRODUCTS</h3>
          <router-link class="":to="{ path: 'shop/pahukama-colcom' }">
            <button class="btn btn-outline-dark btn-lg">View All</button>
          </router-link>
        </div>
        <div class="ps-shopping-product">
          <div class="row">
            <div
              v-for="product in colcom"
              :key="product.id"
              class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6"
            >
              <Product-card :product="product"></Product-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="desktop-section promo no-bottom">
      <div class="container-max-width">
        <router-link :to="{ name: 'shop.specials' }">
          <div class="card-image">
            <img :src="special" alt="promo" />
          </div>
        </router-link>
      </div>
    </div>
    <div class="desktop-section no-bottom">
      <div class="container-max-width">
        <div style="background-color: #ffe8df;" class="d-flex justify-content-between pt-4 p-2">
          <h3 class="text-left align-self-center w-75">PAHUKAMA SUPERMARKET</h3>
          <router-link :to="{ path: 'shop/pahukama-supermarket' }">
            <button class="btn btn-outline-dark btn-lg">View All</button>
          </router-link>
        </div>
        <div class="ps-shopping-product">
          <div class="row">
            <div
              v-for="product in supermarket"
              :key="product.id"
              class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6"
            >
              <Product-card :product="product"></Product-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="desktop-section">
      <div class="container-max-width">
        <div style="background-color: #ffe8df;" class="d-flex justify-content-between pt-4 p-2">
          <h3 class="text-left w-75">PAHUKAMA HARDWARE SUPPLIES</h3>
          <router-link :to="{ path: 'shop/pahukama-hardware' }">
            <button class="btn btn-outline-dark btn-lg">View All</button>
          </router-link>
        </div>
        <div class="ps-shopping-product">
          <div class="row">
            <div
              v-for="product in hardware"
              :key="product.id"
              class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6"
            >
              <Product-card :product="product"></Product-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="desktop-section">
      <div class="container-max-width">
        <div style="background-color: #ffe8df;" class="d-flex justify-content-between pt-4 p-2">
          <h3 class="text-left w-75">PAHUKAMA BUILDING MATERIALS DEPOT</h3>
          <router-link :to="{ path: 'shop/pahukama-building-materials' }">
            <button class="btn btn-outline-dark btn-lg">View All</button>
          </router-link>
        </div>
        <div class="ps-shopping-product">
          <div class="row">
            <div
              v-for="product in buildingMaterials"
              :key="product.id"
              class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6"
            >
              <Product-card :product="product"></Product-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="desktop-section">
      <div class="container-max-width">
        <div style="background-color: #ffe8df;" class="d-flex justify-content-between pt-4 p-2">
          <h3 class="text-left ml-3 w-75">PAHUKAMA GREENS MARKET</h3>
          <router-link :to="{ path: 'shop/pahukama-greens' }">
            <button class="btn btn-outline-dark btn-lg">View All</button>
          </router-link>
        </div>
        <div class="ps-shopping-product">
          <div class="row">
            <div
              v-for="product in greens"
              :key="product.id"
              class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6"
            >
              <Product-card :product="product"></Product-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="desktop-section">
      <div class="container-max-width">
        <div style="background-color: #ffe8df;" class="d-flex justify-content-between pt-4 p-2">
          <h3 class="text-left ml-3 w-75">PAHUKAMA FOODS TAKEAWAYS</h3>
          <router-link :to="{ path: 'shop/pahukama-takeaways' }">
            <button class="btn btn-outline-dark btn-lg">View All</button>
          </router-link>
        </div>
        <div class="ps-shopping-product">
          <div class="row">
            <div
              v-for="product in takeaway"
              :key="product.id"
              class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6"
            >
              <Product-card :product="product"></Product-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="desktop-section">
      <div class="container-max-width">
        <div style="background-color: #ffe8df;" class="d-flex justify-content-between pt-4 p-2">
          <h3 class="text-left ml-3 w-75">PAHUKAMA MEATS BUTCHERY</h3>
          <router-link :to="{ path: 'shop/pahukama-butchery' }">
            <button class="btn btn-outline-dark btn-lg">View All</button>
          </router-link>
        </div>

        <div v-if="this.supermeats.length !== 0" class="ps-shopping-product">
          <div class="row">
            <div
              v-for="product in supermeats"
              :key="product.id"
              class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6"
            >
              <Product-card :product="product"></Product-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="desktop-section">
      <div class="container-max-width">
        <div style="background-color: #ffe8df;" class="d-flex justify-content-between pt-4 p-2">
          <h3 class="text-left ml-3 w-75">PAHUKAMA BOTTLESTORE LIQUORS</h3>
          <router-link :to="{ path: 'shop/pahukama-liquor' }">
            <button class="btn btn-outline-dark btn-lg">View All</button>
          </router-link>
        </div>
        <div class="ps-shopping-product">
          <div class="row">
            <div
              v-for="product in bottleStore"
              :key="product.id"
              class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6"
            >
              <Product-card :product="product"></Product-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="desktop-section">
      <div class="container-max-width">
        <div style="background-color: #ffe8df;" class="d-flex justify-content-between pt-4 p-2">
          <h3 class="text-left ml-3 w-75">PAHUKAMA TREATS & EVENTS</h3>
          <router-link :to="{ path: 'shop/pahukama-treats' }">
            <button class="btn btn-outline-dark btn-lg">View All</button>
          </router-link>
        </div>
        <div v-if="this.treats.length !== 0" class="ps-shopping-product">
          <div class="row">
            <div
              v-for="product in treats"
              :key="product.id"
              class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6"
            >
              <Product-card :product="product"></Product-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="desktop-section">
      <div class="container-max-width">
        <div style="background-color: #ffe8df;" class="d-flex justify-content-between pt-4 p-2">
          <h3 class="text-left ml-3 w-75">PAHUKAMA BAKERY EATS</h3>
          <router-link :to="{ path: 'shop/pahukama-bakery' }">
            <button class="btn btn-outline-dark btn-lg">View All</button>
          </router-link>
        </div>
        <div v-if="this.bakery.length !== 0" class="ps-shopping-product">
          <div class="row">
            <div
              v-for="product in bakery"
              :key="product.id"
              class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6"
            >
              <Product-card :product="product"></Product-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="desktop-section">
      <div class="container-max-width">
        <div style="background-color: #ffe8df;" class="d-flex justify-content-between pt-4 p-2">
          <h3 class="text-left ml-3 w-75">PAHUKAMA PATOWNSHIP TRADERS</h3>
          <router-link :to="{ path: 'shop/pahukama-township' }">
            <button class="btn btn-outline-dark btn-lg">View All</button>
          </router-link>
        </div>
        <div class="ps-shopping-product">
          <div class="row">
            <div
              v-for="product in township"
              :key="product.id"
              class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6"
            >
              <Product-card :product="product"></Product-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    
        <div class="pb-3 hide-on-desktop" style="background-color: black; width: 100vw;">
      <img class="px-2 pt-3" src="https://pahukama-onestopshop.s3.amazonaws.com/logos-banners-products-services/multicare.jpg " alt=""/>
      
       <div class="px-2" style="display: flex; ">
              <span style="padding: 5px 0px">
                <router-link
                  :to="{ name: 'dial_from_263' }"
                  class="ls-hover-shadow"
                >
                  <img
                    style="width: 93%; aspect-ratio: 1; object-fit: contain"
                    v-lazy="'/img/button1.png'"
                  />
                </router-link>
              </span>
              <span style="padding: 5px 0px">
                <router-link
                  class="ls-hover-shadow"
                  :to="{ name: 'runner_requests' }"
                >
                  <img
                    style="width: 93%; aspect-ratio: 1; object-fit: contain"
                    v-lazy="'/img/button2.png'"
                  />
                </router-link>
              </span>

              <span style="padding: 5px 0px">
                <router-link
                  class="ls-hover-shadow"
                  :to="{ name: 'topup_now' }"
                >
                  <img
                    style="width: 93%; aspect-ratio: 1; object-fit: contain"
                    v-lazy="'/img/button3.png'"
                  />
                </router-link>
              </span>
            </div>

            <div class="px-2" style="display: flex" >
              
                <span style="padding: 5px 0px">
                  <a href="/simple_pay_direct" class="ls-hover-shadow">
                    <img
                      style="
                        width: 93%;
                        aspect-ratio: 1;
                        
                        object-fit: contain;
                      "
                      src="/img/button4.png"
                    />
                  </a>
                </span>
              
              <span style="padding: 5px 0px" class="ls-hover-shadow">
                <a href="/specialists-jobs-tasks">
                  <img
                    style="
                      width: 93%;
                      aspect-ratio: 1;
                      
                      object-fit: contain;
                    "
                    v-lazy="'/img/button5.png'"
                  />
                </a>
              </span>
              <span style="padding: 5px 0px" class="ls-hover-shadow">
                <a href="/vouchers">
                  <img
                    style="width: 93%; aspect-ratio: 1; object-fit: contain"
                    src="/img/button8.png"
                  />
                </a>
              </span>
            </div>

            <div
            style="
        
              margin-bottom: -15px;
              margin-top: 0px;
            "
          >
            
          </div>

          <div style="display: flex" class="mt-3 px-2">
              <span style="padding: 5px 0px">
                <a href="/listings/category" class="ls-hover-shadow">
                  <img
                    style="width: 93%; aspect-ratio: 1; object-fit: contain"
                    src="/img/button9.png"
                  />
                </a>
              </span>
              <span style="padding: 5px 0px">
                <a class="ls-hover-shadow" href="/prescriptions">
                  <img
                    style="width: 93%; aspect-ratio: 1; object-fit: contain"
                    src="/img/button11.png"
                  />
                </a>
              </span>
              
            </div>
            
      </div> 
      
  </div>
</template>
<script>
import axios from "axios";
import { mapState } from "vuex";
import HomeSlideshowFeaturedBrands from "../../../components/HomeSlideshowFeaturedBrands";
import ProductCard from "../../../components/Cards/ProductCard.vue";

const url = `${window.location.origin}`;
export default {
  name: "home",
  components: {
    HomeSlideshowFeaturedBrands,
    ProductCard,
  },
  data() {
    return {
      header: `${url}/img/landing/home-1.jpg`,
      placeholder: `${url}/img/landing/placeholder.png`,
      slide: `${url}/img/landing/slide/slide.png`,
      slide1: `${url}/img/landing/slide/slide1.png`,
      slide2: `${url}/img/landing/slide/slide2.png`,
      slide3: `${url}/img/landing/slide/slide3.png`,
      special: `${url}/img/landing/AllSpecials.png`,
      supermarket: [],
      butchery: [],
      colcom: [],
      bottleStore: [],
      hardware: [],
      greens: [],
      takeaway: [],
      greenwood: [],
      supermeats: [],
      bakery: [],
      township: [],
      buildingMaterials: [],
      treats: [],
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async fetchData(shopId) {
      this.$loading(true);
      try {
        const response = await axios.get(
          `products?shop=${shopId}&limit=${6}`
        );
        this.$loading(false);
        return response.data.data.slice(0,6);
      } catch (error) {
        console.error("Error fetching data:", error);
        this.$loading(false);
        return null;
      }
    },
  },
  computed: {
    ...mapState({
      
      auth: (state) => state.auth,
      
    }),
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    const shopIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
    Promise.all(shopIds.map(this.fetchData)).then((results) => {
      [
        this.greenwood,
        this.supermarket,
        this.supermeats,
        this.bakery,
        this.bottleStore,
        this.greens,
        this.hardware,
        this.treats,
        this.takeaway,
        this.colcom,
        this.butchery,
        this.buildingMaterials,
        this.township,
      ] = results;
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style>
.content-center {

  color: #ffffff;
  display: flex;
  justify-content: center;
}
.desktop-section {
  padding: 5px 0;
  position: relative;
  display: flex;
  justify-content: center;
}

.container {
  height: 100%;
  z-index: 1;
  text-align: center;
  position: relative;
}

.container-max-width {
  max-width: 1600px;
  height: 100%;
  z-index: 1;
  text-align: center;
  position: relative;
}

.header-text {
  padding-top: 32vh;
  padding-bottom: 40px;
}

.product-single-card {
  padding: 20px;
  border-radius: 1rem;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  transition: 0.5s ease-in;
}

.product-single-card:hover {
  box-shadow: 0 0.5rem 1rem #d1d9e6;
}

.product-single-card .product-info {
  padding: 15px 0 0 0;
}

.product-single-card .product-top-area {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
}

.product-single-card .product-top-area .product-discount {
  position: absolute;
  top: 10px;
  left: 10px;
  background: white;
  border-radius: 3px;
  padding: 5px 10px;
  box-shadow: 1px 1px 28.5px -7px #dddddd;
  user-select: none;
  z-index: 999;
}

.product-single-card .product-top-area .product-img {
  aspect-ratio: 1/1;
  overflow: hidden;
}

.product-single-card .product-top-area .product-img .first-view {
  transition: 0.5s ease-in;
}

.product-single-card .product-top-area .product-img .hover-view {
  opacity: 0;
  transition: 0.5s ease-in;
}

.product-single-card .product-top-area .sideicons {
  position: absolute;
  right: 15px;
  display: grid;
  gap: 10px;
}

.product-single-card .product-top-area .sideicons .sideicons-btn {
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  border: none;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transform: translateX(60px);
  transition: 0.3s ease-in;
  -webkit-box-shadow: 1px 1px 28.5px -7px #dddddd;
  -moz-box-shadow: 1px 1px 28.5px -7px #dddddd;
  box-shadow: 1px 1px 28.5px -7px #dddddd;
}

.product-single-card .product-top-area:hover .sideicons .sideicons-btn {
  opacity: 100%;
  visibility: visible;
  transform: translateX(0);
}

.product-single-card .product-info .product-category {
  font-weight: 600;
  opacity: 60%;
}

.product-single-card .product-info .product-title {
  font-size: 16px;
  font-weight: 600;
}

.product-single-card .product-info,
.product-single-card .product-info .new-price {
  padding-right: 15px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
}

.service {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 2rem;
  border-radius: 1rem;
  background-color: #ffe8df;
  overflow: hidden;
}

.service__img {
  width: 180px;
  height: auto;
  padding: 3rem 0;
  transition: 0.5s;
}

.service:hover {
  box-shadow: 0 0.5rem 1rem #d1d9e6;
}
</style>
